import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { URI } from "../../../config/Contracts";
import styles from "./Charts.module.sass";
import { actualPriceState } from "../../../states/actualPriceState";
import { symbolState } from "../../../states/symbolState";
import { useParams } from "react-router-dom";

const Actions = () => {
    const [candleInterval, setCandleInterval] = useState("1");
    const chartContainerRef = useRef(null);
    const { paramSymbol } = useParams();
    const [symbol, setSymbol] = useRecoilState(symbolState);
    const [actualPrice, setActualPrice] = useRecoilState(actualPriceState);

    useEffect(() => {
        if (!symbol) {
            const resolvedSymbol = paramSymbol || "rypl";
            setSymbol(resolvedSymbol);
        }
    }, [paramSymbol, symbol, setSymbol]);

    useEffect(() => {
        let tvWidget = null;
        let subscriptionId = null;

        const initializeTradingView = () => {
            if (window.TradingView && window.Datafeeds) {
                const datafeed = new window.Datafeeds.UDFCompatibleDatafeed(`${URI}/udf`);
                tvWidget = new window.TradingView.widget({
                    container: chartContainerRef.current.id,
                    datafeed,
                    library_path: "/charting_library/",
                    symbol: symbol || "rypl",
                    interval: candleInterval,
                    locale: "en",
                    fullscreen: false,
                    autosize: true,
                    debug: true,
                    theme: "Dark",
                });

                tvWidget.onChartReady(() => {
                    datafeed.subscribeBars(
                        { ticker: symbol || "rypl" }, // Información del símbolo
                        candleInterval,              // Intervalo
                        (bar) => {
                            setActualPrice(bar.close);
                        },
                        "uniqueSubscriberID" // Identificador único para la suscripción
                    );
                });
            } else {
                console.error("Error: TradingView or Datafeeds not loaded.");
            }
        };

        const loadScript = (src, onLoad, onError) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = onLoad;
            script.onerror = onError || (() => console.error(`Error loading script: ${src}`));
            document.head.appendChild(script);
        };

        loadScript("/charting_library/charting_library.standalone.js", () => {
            loadScript("/datafeeds/udf/dist/bundle.js", initializeTradingView);
        });

        return () => {
            if (tvWidget) {
                tvWidget.remove();
            }
            if (subscriptionId) {
                tvWidget._datafeed.unsubscribeBars(subscriptionId);
            }
        };
    }, [symbol, candleInterval, setActualPrice]);

    return (
        <div className={styles.charts}>
            <div
                ref={chartContainerRef}
                id="chartContainer"
                style={{
                    width: "100%",
                    height: "600px",
                    position: "relative",
                }}
            ></div>
        </div>
    );
};

export default Actions;